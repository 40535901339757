<template v-if="formActive">

    <FormItem id="commodity_group_id" :label="`Commodity group`" v-model="commodity.commodity_group_id" type="select" :options="commodityGroups" :required="true" />
    <FormItem id="name" :label="`Name`" v-model="commodity.name" :required="true" />
    <FormItem id="static_chassis_number_length" :label="`17-digit chassis number`" v-model="commodity.static_chassis_number_length" type="checkbox" />

</template>

<script>
    import commoditygroupservice from '@/services/CommoditygroupService'
    
    export default {
        name: 'CommoditygroupForm',
        props: ['itemData'],
        data() {
            return {
                commodity: {
                    "type": null,
                    "static_chassis_number_length": null,
                    "commodity_group_id": null
                },
                commodityGroups:[],
                submitted: false,
                loading: false,
                invalid: {},
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.commodity = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                this.invalid = {};
                let valid = true;

                let required = document.querySelectorAll('#formModal input[required],#formModal select[required]');

                for(let field of required) {
                    if(!field.value || field.value == this.$t('form.select')) {
                        field.classList.add('is-invalid');
                        valid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(!valid) alert(this.$t('errors.empty_fields'));
                return valid;
            },
            getData() {
                return this.commodity;
            },
            setData(data) {
                this.commodity = data
            },
            reset() {
                this.commodity = {
                    "type": null,
                    "static_chassis_number_length": null,
                    "commodity_group_id": null
                }
                this.loading = false
                this.invalid = {}
                this.getCommodityGroups();
            },
            getCommodityGroups() {
                commoditygroupservice.index().then(response => {
                    this.commodityGroups = response.data
                }).catch(error => {
                    console.log('error', error)
                })
            }
            
        },
        mounted() {
            //this.reset()
        }
    }
</script>
<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="['commodity_group.name', 'name']"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :selectFields=selectFields
    >
        <template #columns>
            <Column field="commodity_group.name" sortField="commodity_group_id" :header="$tc('navigation.commoditygroups', 1)" style="width: 20%"></Column> 
            <Column field="name" :header="$t('overview.name')"></Column>
        </template>

        <template #form>
            <CommodityForm ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import CommodityForm from '@/components/commodity/CommodityForm.vue';
import commodityService from '@/services/CommodityService';
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        CommodityForm
    },
    data() {
        return {
            item: {},
            itemService: commodityService,
            itemFormRef: null,
            selectFields: [
                "id",
                "name",
                "commodity_group_id",
                "order_by",
                {
                    "commodityGroup": [
                        "id",
                        "name"
                    ]
                }
            ]
        }
    },
    mounted() {
        if(!store.getters.getCompanyHq){
            window.location.href = '/';
            return;
        }
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>